<template>
  <policy>
    <cookie-policy></cookie-policy>
  </policy>
</template>

<script>
import Policy from "@/layouts/Policy.vue"
import CookiePolicy from "@/components/policy/CookiePolicy.vue"

export default {
  components: { Policy, CookiePolicy }
}
</script>
